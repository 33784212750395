import Button from "@rescui/button";
import { DownloadIcon, LoadingIcon } from "@rescui/icons";
import React from "react";
import { Link } from "react-router-dom";

import useAuthenticatedUser from "../../auth/useAuthenticatedUser";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import Layout from "../../layout/Layout";
import Main from "../../layout/Main";
import * as mediatypes from "../../shared/mediatypes";
import useDocumentTitle from "../../shared/useDocumentTitle";
import useDownload from "../../shared/useDownload";

export type Props = Record<string, never>;

function Landing() {
  useDocumentTitle("JetID");

  const user = useAuthenticatedUser();

  const mailingListReport = useDownload(
    "mailing_list_report.xlsx",
    mediatypes.xlsx,
    process.env.REACT_APP_API_URL + "/mailing_list_report",
    {
      method: "POST",
      credentials: "include",
      timeout: 60000,
    }
  );

  return (
    <Layout>
      <Header title="Internal" />
      <Main>
        <ul className="flex flex-col gap-1">
          <li>
            <Link to={{ pathname: "/timesheet" }}>Fill timesheet</Link>
          </li>
          {user.isLoading ? (
            <li>
              <LoadingIcon />
            </li>
          ) : user.data?.admin ? (
            <>
              <li>
                <Link to={{ pathname: "/catering" }}>Catering info</Link>
              </li>
              <li>
                <Button
                  onClick={mailingListReport.download}
                  size="xs"
                  icon={
                    mailingListReport.isLoading ? (
                      <LoadingIcon />
                    ) : (
                      <DownloadIcon />
                    )
                  }
                  disabled={mailingListReport.isLoading}
                >
                  Mailing list report
                </Button>
              </li>
            </>
          ) : null}
        </ul>
      </Main>
      <Footer />
    </Layout>
  );
}

export default Landing;
