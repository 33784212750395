import { ReactNode } from "react";

import logo from "../images/jetid-icon.svg";

export type Props = { title?: string; children?: ReactNode };

function Header({ title, children }: Props) {
  return (
    <header className="mb-2">
      <div className="flex items-center justify-between gap-1">
        <div className="flex items-center gap-1">
          <a href="/">
            <img src={logo} className="logo" alt="JetID logo" />
          </a>
          <h1 className="rs-h2 m-0">{title}</h1>
        </div>
        {children}
      </div>
    </header>
  );
}

export default Header;
