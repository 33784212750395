import Button from "@rescui/button";
import { DownloadIcon } from "@rescui/icons";
import React, { Component } from "react";

import * as mediatypes from "../../shared/mediatypes";
import {
  downloadAsFile,
  ErrorAwareState,
  handleLoadingErrors,
} from "../../Util/common";

interface State extends ErrorAwareState {
  token: string | null;
  loading_schedule: boolean;
  loading_report: boolean;
}

class MassageLanding extends Component<any, State> {
  state: State = {
    token: new URLSearchParams(window.location.hash.substr(1)).get(
      "access_token"
    ),
    loading_schedule: false,
    loading_report: false,
  };

  render() {
    if (this.state.loadingError) {
      return <div>{this.state.loadingError}</div>;
    }
    if (!this.state.token) {
      localStorage.setItem("location", window.location.href);
      window.location.href =
        "https://accounts.google.com/o/oauth2/v2/auth?redirect_uri=https://" +
        process.env.REACT_APP_DOMAIN +
        "/massage&prompt=consent&response_type=token&client_id=" +
        process.env.REACT_APP_GCP_CLIENT_ID +
        "&scope=https://www.googleapis.com/auth/calendar.events.readonly";
      return <div />;
    }
    return (
      <div>
        <Button
          mode="rock"
          icon={<DownloadIcon />}
          disabled={this.state.loading_schedule}
          onClick={() => {
            this.setState({ loading_schedule: true });
            handleLoadingErrors(
              this,
              fetch(
                process.env.REACT_APP_INCOGNITO_API_URL +
                  "/massage/schedule?token=" +
                  this.state.token,
                {
                  headers: {
                    Accept: mediatypes.xlsx,
                  },
                }
              )
                .then((res) => (res.ok ? res : Promise.reject(res.text())))
                .then((res) => {
                  downloadAsFile(res, mediatypes.xlsx, "schedule.xlsx");
                  this.setState({ loading_schedule: false });
                })
            );
          }}
        >
          Schedule
        </Button>
        <Button
          mode="rock"
          icon={<DownloadIcon />}
          disabled={this.state.loading_report}
          onClick={() => {
            this.setState({ loading_report: true });
            handleLoadingErrors(
              this,
              fetch(
                process.env.REACT_APP_INCOGNITO_API_URL +
                  "/massage/report?token=" +
                  this.state.token,
                {
                  headers: {
                    Accept: mediatypes.xlsx,
                  },
                }
              )
                .then((res) => (res.ok ? res : Promise.reject(res)))
                .then((res) => {
                  downloadAsFile(res, mediatypes.xlsx, "report.xlsx");
                  this.setState({ loading_report: false });
                })
            );
          }}
        >
          Report
        </Button>
      </div>
    );
  }
}

export default MassageLanding;
