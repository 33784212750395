import "./timesheets.css";

import Button from "@rescui/button";
import { LoadingIcon } from "@rescui/icons";
import Input from "@rescui/input";
import React, { Component } from "react";

import {
  ErrorAwareState,
  fetchJsonOrLogin,
  fetchOrLogin,
} from "../../Util/common";
import { format, parse } from "./duration";

interface State extends ErrorAwareState {
  customEmail: string;
  sheet: Sheet | null;
  actualHours: Map<number, string>;
  workStart: Map<number, string>;
  workEnd: Map<number, string>;
  lunchBreak: Map<number, string>;
  submitting: boolean;
  submitted: boolean;
  customRecipient: string;
}

interface Sheet {
  employeeName: string;
  employeeEmail: string;
  month: string;
  adminMode: boolean;
  recipients: string;
  table: DayEntry[];
}

interface DayEntry {
  dayOfMonth: number;
  defaultHours: string;
  workStart: string;
  workEnd: string;
  lunchBreak: string;
  dayOfWeek: string;
  absence: string;
  mayWork: boolean;
}

interface StoredState {
  month: string;
  email: string;
  workStart: [number, string][];
  workEnd: [number, string][];
  lunchBreak: [number, string][];
}

const maintainer = {
  name: "Internal Development",
  url: "https://jetbrains.team/team?team=4ImFO13lCNN7",
} as const;

function sum(times: string[]): string {
  const duration = times
    .map((input: string) => {
      try {
        return parse(input);
      } catch (e) {
        return 0; // count invalid or empty inputs as 0
      }
    })
    .reduce((a, b) => a + b);

  return format(duration);
}

function totalDefault(sheet: Sheet) {
  return sum(sheet.table.map((e) => e.defaultHours));
}

function totalActual(sheet: Sheet, hours: Map<number, string>) {
  return sum(sheet.table.map((e) => hours.get(e.dayOfMonth) || "0"));
}

function serialize(sheet: Sheet, state: State, warnings: Map<number, string>) {
  const formatTime = (time: string | undefined) => {
    try {
      const duration = parse(time ?? "0:00");
      return format(duration);
    } catch (err) {
      return time;
    }
  };

  const data = [
    ["Timesheet"],
    [],
    ["Month", sheet.month],
    ["Employee", sheet.employeeName],
    [],
    [
      "Day of month",
      "Day of week",
      "Default hours",
      "Work start",
      "Work end",
      "Lunch break",
      "Work hours",
      "Notes",
    ],
    [],
    ...sheet.table.map((entry) => {
      const warning = warnings.get(entry.dayOfMonth);

      let note = `${warning ? `!! ${warning} !!` : ""} ${entry.absence}`.trim();
      if (note.length > 0) note = '"' + note + '"';

      return [
        entry.dayOfMonth,
        entry.dayOfWeek,
        `"${formatTime(entry.defaultHours)}"`,
        `"${formatTime(state.workStart.get(entry.dayOfMonth))}"`,
        `"${formatTime(state.workEnd.get(entry.dayOfMonth))}"`,
        `"${formatTime(state.lunchBreak.get(entry.dayOfMonth))}"`,
        `"${formatTime(state.actualHours.get(entry.dayOfMonth))}"`,
        note,
      ];
    }),
    [],
    [
      "Total",
      "",
      totalDefault(sheet),
      "",
      "",
      "",
      totalActual(sheet, state.actualHours),
    ],
  ];

  return data.map((row) => row.join(",")).join("\n");
}

class Timesheet extends Component<any, State> {
  state: State = {
    sheet: null,
    customEmail: "",
    actualHours: new Map(),
    workStart: new Map(),
    workEnd: new Map(),
    lunchBreak: new Map(),
    submitting: false,
    submitted: false,
    customRecipient: "",
  };

  private readonly persistentKey = "timesheet-data";

  componentDidMount() {
    document.title = "JetID Timesheet Generator";
    this.loadDefaults(this.loadState());
  }

  private loadState(): StoredState | null {
    const data = localStorage.getItem(this.persistentKey);
    try {
      return data ? JSON.parse(data) : null;
    } catch (e) {
      console.log("Cannot load stored state from " + data);
    }
    return null;
  }

  private loadDefaults(stored: StoredState | null) {
    fetchJsonOrLogin(
      this,
      process.env.REACT_APP_API_URL +
        "/default_timesheet" +
        (this.state.customEmail.length > 0
          ? "?email=" + this.state.customEmail
          : ""),
      "POST"
    ).then((value) => {
      const sheet: Sheet = value;
      this.setState({ sheet: sheet });
      if (
        stored &&
        (stored.month !== sheet.month || stored.email !== sheet.employeeEmail)
      ) {
        stored = null;
      }
      this.updateActualHours(
        sheet,
        stored ? new Map(stored.workStart) : new Map(),
        stored ? new Map(stored.workEnd) : new Map(),
        stored ? new Map(stored.lunchBreak) : new Map()
      );
    });
  }

  render() {
    if (this.state.loadingError) {
      return (
        <div className="error">
          Error loading data. <br />
          <br />
          Details: {this.state.loadingError}
          <br />
          <br />
          Try reloading the page. If problems persist, please contact{" "}
          <a href={maintainer.url}>{maintainer.name}</a>
        </div>
      );
    }

    const sheet = this.state.sheet;
    if (sheet === null) {
      return <LoadingIcon />;
    }

    const warnings = this.warnings(sheet);

    const disableSubmit = sheet.table.some((entry) => this.looksEmpty(entry));

    return (
      <div className="timesheet_root">
        <h1>
          {sheet.employeeName}: timesheet for {sheet.month}
        </h1>
        <table className="timesheet_table">
          <thead>
            <tr>
              <th>Day</th>
              <th>Default working hours</th>
              <th>Work start</th>
              <th>Work end</th>
              <th>Lunch break</th>
              <th>Actual working hours</th>
              <th>Notes</th>
            </tr>
          </thead>
          <tbody>
            {sheet.table.map((entry) => (
              <tr
                key={entry.dayOfMonth}
                className={
                  entry.dayOfWeek === "Sat" || entry.dayOfWeek === "Sun"
                    ? "timesheet_row_weekend"
                    : "timesheet_row_normal"
                }
              >
                <td>
                  {entry.dayOfMonth} ({entry.dayOfWeek})
                </td>
                <td align="center">{entry.defaultHours}</td>
                <td align="center">
                  {!entry.mayWork ? (
                    <div />
                  ) : (
                    <Input
                      type="text"
                      size="s"
                      id={"workStart" + entry.dayOfMonth}
                      value={this.state.workStart.get(entry.dayOfMonth) || ""}
                      onChange={(event) => {
                        const workStart = new Map(this.state.workStart);
                        workStart.set(entry.dayOfMonth, event.target.value);
                        this.updateActualHours(
                          sheet,
                          workStart,
                          this.state.workEnd,
                          this.state.lunchBreak
                        );
                      }}
                      placeholder="H or HH:MM"
                      onKeyDown={(event) => {
                        if (
                          event.key === "ArrowDown" ||
                          event.key === "ArrowUp"
                        ) {
                          let index = entry.dayOfMonth;
                          do {
                            index =
                              index + (event.key === "ArrowDown" ? 1 : -1);
                            const nextField = document.getElementById(
                              "workStart" + index
                            );
                            if (nextField) {
                              nextField.focus();
                              return;
                            }
                          } while (index >= 1 && index <= 31);
                        }
                      }}
                    />
                  )}
                </td>
                <td align="center">
                  {!entry.mayWork ? (
                    <div />
                  ) : (
                    <Input
                      type="text"
                      size="s"
                      id={"workEnd" + entry.dayOfMonth}
                      value={this.state.workEnd.get(entry.dayOfMonth) || ""}
                      onChange={(event) => {
                        const workEnd = new Map(this.state.workEnd);
                        workEnd.set(entry.dayOfMonth, event.target.value);
                        this.updateActualHours(
                          sheet,
                          this.state.workStart,
                          workEnd,
                          this.state.lunchBreak
                        );
                      }}
                      placeholder="H or HH:MM"
                      onKeyDown={(event) => {
                        if (
                          event.key === "ArrowDown" ||
                          event.key === "ArrowUp"
                        ) {
                          let index = entry.dayOfMonth;
                          do {
                            index =
                              index + (event.key === "ArrowDown" ? 1 : -1);
                            const nextField = document.getElementById(
                              "workEnd" + index
                            );
                            if (nextField) {
                              nextField.focus();
                              return;
                            }
                          } while (index >= 1 && index <= 31);
                        }
                      }}
                    />
                  )}
                </td>
                <td align="center">
                  {!entry.mayWork ? (
                    <div />
                  ) : (
                    <Input
                      type="text"
                      size="s"
                      id={"lunchBreak" + entry.dayOfMonth}
                      value={this.state.lunchBreak.get(entry.dayOfMonth) || ""}
                      onChange={(event) => {
                        const lunchBreak = new Map(this.state.lunchBreak);
                        lunchBreak.set(entry.dayOfMonth, event.target.value);
                        this.updateActualHours(
                          sheet,
                          this.state.workStart,
                          this.state.workEnd,
                          lunchBreak
                        );
                      }}
                      placeholder="H or HH:MM"
                      onKeyDown={(event) => {
                        if (
                          event.key === "ArrowDown" ||
                          event.key === "ArrowUp"
                        ) {
                          let index = entry.dayOfMonth;
                          do {
                            index =
                              index + (event.key === "ArrowDown" ? 1 : -1);
                            const nextField = document.getElementById(
                              "lunchBreak" + index
                            );
                            if (nextField) {
                              nextField.focus();
                              return;
                            }
                          } while (index >= 1 && index <= 31);
                        }
                      }}
                    />
                  )}
                </td>

                <td align="center">
                  {this.state.actualHours.get(entry.dayOfMonth)}
                </td>

                {warnings.has(entry.dayOfMonth) && !this.looksEmpty(entry) ? (
                  <td className="inspection_error">
                    {[entry.absence, warnings.get(entry.dayOfMonth) || ""]
                      .filter((s) => s.length > 0)
                      .join("; ")}
                  </td>
                ) : (
                  <td>{entry.absence}</td>
                )}
              </tr>
            ))}
            <tr>
              <td align="right">
                <b>Total</b>
              </td>
              <td align="center">{totalDefault(sheet)}</td>
              <td />
              <td />
              <td />
              <td align="center">
                {totalActual(sheet, this.state.actualHours)}
              </td>
              <td />
            </tr>
          </tbody>
        </table>
        <div className="timesheet_bottom">
          <Button
            disabled={this.state.submitted || this.state.submitting}
            onClick={() => {
              const workStart = new Map(this.state.workStart);
              const workEnd = new Map(this.state.workEnd);
              const lunchBreak = new Map(this.state.lunchBreak);
              sheet.table.forEach((entry) => {
                if (this.looksEmpty(entry)) {
                  workStart.set(entry.dayOfMonth, entry.workStart);
                  workEnd.set(entry.dayOfMonth, entry.workEnd);
                  lunchBreak.set(entry.dayOfMonth, entry.lunchBreak);
                }
              });
              this.updateActualHours(sheet, workStart, workEnd, lunchBreak);
            }}
          >
            Fill default values
          </Button>
          <Button
            disabled={
              disableSubmit || this.state.submitted || this.state.submitting
            }
            onClick={() => this.handleSubmit(sheet, null)}
          >
            {this.state.submitting
              ? "Submitting..."
              : this.state.submitted
              ? "Submitted!"
              : "Submit to HR"}
          </Button>
        </div>
        <div className="timesheet_bottom">
          <Button
            onClick={() =>
              this.updateActualHours(sheet, new Map(), new Map(), new Map())
            }
          >
            Clear
          </Button>
        </div>
        <div>
          For questions, comments, and praise, contact{" "}
          <a href={maintainer.url}>{maintainer.name}</a>
        </div>
        {this.adminControls(sheet, disableSubmit)}
      </div>
    );
  }

  private updateActualHours(
    sheet: Sheet,
    workStart: Map<number, string>,
    workEnd: Map<number, string>,
    lunchBreak: Map<number, string>
  ) {
    const days = new Set([
      ...workStart.keys(),
      ...workEnd.keys(),
      ...lunchBreak.keys(),
    ]);
    const actualHours = new Map<number, string>();
    days.forEach((day) => {
      const actual =
        parse(workEnd.get(day) ?? "17:30") -
        parse(workStart.get(day) ?? "9:00") -
        parse(lunchBreak.get(day) ?? "0");
      actualHours.set(day, format(actual));
    });
    this.setState({
      workStart: workStart,
      workEnd: workEnd,
      lunchBreak: lunchBreak,
      actualHours: actualHours,
    });
    localStorage.setItem(
      this.persistentKey,
      JSON.stringify({
        month: sheet.month,
        email: sheet.employeeEmail,
        workStart: [...workStart],
        workEnd: [...workEnd],
        lunchBreak: [...lunchBreak],
        actualHours: [...actualHours],
      } as StoredState)
    );
  }

  private looksEmpty = (entry: DayEntry) =>
    entry.mayWork && !this.state.workStart.get(entry.dayOfMonth);

  private warnings(sheet: Sheet) {
    const result = new Map<number, string>();
    sheet.table.forEach((e) => {
      if (e.dayOfWeek === "Sun") return;

      const actual =
        this.state.actualHours.get(e.dayOfMonth) || (e.absence ? "0" : "");

      try {
        const duration = parse(actual);

        if (duration > 0) {
          if (!e.mayWork) {
            result.set(e.dayOfMonth, "This day is supposed to be non-working");
          } else if (duration >= 11 * 3600_000) {
            result.set(e.dayOfMonth, "Too much for one day");
          }
        }
      } catch (err: any) {
        const message = err instanceof Error ? err.message : err.toString();
        result.set(e.dayOfMonth, message);
      }
    });

    return result;
  }

  private handleSubmit(sheet: Sheet, customRecipient: string | null) {
    let message = "Send the timesheet to the HR team and your team leads?";

    const warnings = this.warnings(sheet);
    if (warnings.size > 0) {
      const keys = Array.from(warnings.keys()).sort((a, b) => a - b);
      message += "\nNote that you have warnings:\n\n";
      keys.forEach(
        (key) => (message += key + "th: " + warnings.get(key) + "\n")
      );
      message += "\nPlease make sure the data is correct.";
    }

    message += "\n\nAll recipients: " + (customRecipient || sheet.recipients);

    if (!window.confirm(message)) {
      return;
    }

    const data = serialize(sheet, this.state, warnings);

    this.setState({ submitting: true });

    fetchOrLogin(
      this,
      process.env.REACT_APP_API_URL +
        "/submit_timesheet" +
        (customRecipient ? "?to=" + customRecipient : ""),
      "POST",
      data
    ).then((value) => {
      if (value.ok) {
        this.setState({ submitting: false, submitted: true });
      } else {
        console.log("Not OK", value);
        window.alert(
          `Submission failed :(\nPlease try again later. If problems persist, please contact ${maintainer.name} (${maintainer.url})`
        );
        this.setState({ submitting: false });
      }
    });
  }

  private adminControls(sheet: Sheet, disableSubmit: boolean) {
    if (!sheet.adminMode) return <div />;

    return (
      <div>
        <div className="timesheet_bottom">
          Show timesheet for:
          <Input
            value={this.state.customEmail}
            placeholder="email"
            onChange={(event) =>
              this.setState({ customEmail: event.target.value })
            }
          />
          <Button
            onClick={() => {
              localStorage.removeItem(this.persistentKey);
              this.loadDefaults(null);
            }}
          >
            Change
          </Button>
        </div>
        <div className="timesheet_bottom">
          Send timesheet to:
          <Input
            placeholder="email"
            value={this.state.customRecipient}
            onChange={(event) =>
              this.setState({ customRecipient: event.currentTarget.value })
            }
          />
          <Button
            disabled={disableSubmit || this.state.submitting}
            onClick={() => {
              this.handleSubmit(
                sheet,
                this.state.customRecipient.length !== 0
                  ? this.state.customRecipient
                  : null
              );
            }}
          >
            Send
          </Button>
        </div>
      </div>
    );
  }
}

export default Timesheet;
