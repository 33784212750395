import { Button } from "@rescui/button";
import i18next from "i18next";
import React, { useState } from "react";

import { EditBookCopy } from "./BookCopyComponent";
import { EditBookDetails } from "./EditBookDetails";
import {
  AddCallbacks,
  BookDetails,
  BookInstanceWithStatus,
  newBookCopy,
  PageState,
} from "./model";

export function AddBook(props: {
  callbacks: AddCallbacks;
  closeForm: () => void;
  pageState: PageState;
}) {
  const empty: BookDetails = {
    Authors: [],
    Title: "",
    Description: "",
    ISBNs: [],
    Links: [],
    Cover: "",
  };
  const [details, setDetails] = useState(empty);
  const [submitted, setSubmitted] = useState(false);
  const [instances, setInstances] = useState<BookInstanceWithStatus[]>([
    newBookCopy(props.pageState),
  ]);

  return (
    <div className="book-entry">
      <div className="details">
        <EditBookDetails forAdding={true} book={empty} onChange={setDetails} />
        <div>
          <br />
          <Button
            mode="rock"
            size="xs"
            disabled={submitted}
            onClick={(_) => {
              setSubmitted(true);
              props.callbacks.addBook(details, instances);
            }}
          >
            {i18next.t("Add this book")}
          </Button>
          &nbsp;
          <Button
            mode="rock"
            size="xs"
            disabled={submitted}
            onClick={props.closeForm}
          >
            {i18next.t("Cancel")}
          </Button>
        </div>
      </div>
      <div className="copy-list">
        {instances.map((instance, index) => (
          <EditBookCopy
            key={index}
            instance={instance}
            allUsers={props.pageState.allUsers}
            onChange={(updated) =>
              setInstances(
                instances.map((i, idx) => (idx === index ? updated : i))
              )
            }
            pageState={props.pageState}
          />
        ))}
        <br />
        <Button
          mode="rock"
          size="xs"
          onClick={(_) => {
            const copy = instances.slice();
            copy.push(newBookCopy(props.pageState));
            setInstances(copy);
          }}
        >
          {i18next.t("Add another copy")}
        </Button>
      </div>
    </div>
  );
}
