import { Button } from "@rescui/button";
import { Input } from "@rescui/input";
import i18next from "i18next";
import React, { Component } from "react";

import {
  ErrorAwareState,
  fetchJsonOrLogin,
  fetchOrLogin,
} from "../../Util/common";
import { AddBook } from "./AddBook";
import BookComponentList from "./BookComponentList";
import {
  AddCallbacks,
  BookDetails,
  BookInstanceWithStatus,
  BookWithInstances,
  PageState,
  UserWithCountCallbacks,
} from "./model";

interface Props {
  page_state: PageState;
  callbacks: UserWithCountCallbacks;
}

interface State extends ErrorAwareState {
  lazy_search_query: string;
  eager_search_query: string;
  book_list_version: number;
  adding_book: boolean;
}

class LibraryBookSection
  extends Component<Props, State>
  implements AddCallbacks
{
  state = {
    lazy_search_query: "",
    eager_search_query: "",
    book_list_version: 0,
    adding_book: false,
  };

  private create_fetch(
    query: string
  ): (
    last_loaded_id: number,
    batch_size: number
  ) => Promise<BookWithInstances[]> {
    const library_id = this.props.page_state.library.LibraryID;
    return (last_loaded_id: number, batch_size: number) =>
      fetchJsonOrLogin(
        this,
        process.env.REACT_APP_BOOK_API_URL +
          "/view/list_instances?top=" +
          batch_size.toString() +
          "&LibraryID=" +
          library_id.toString() +
          "&query=" +
          encodeURIComponent(query) +
          "&includeWrittenOff=" +
          (this.props.page_state.user.UserRole === "admin" ? "true" : "false") +
          (last_loaded_id !== -1 ? "&skip=" + last_loaded_id.toString() : ""),
        "GET"
      );
  }

  private create_book_filter(query: string) {
    return query.length > 0
      ? (bookWithInstances: BookWithInstances) =>
          [
            bookWithInstances.Book.Title,
            bookWithInstances.Book.Description,
            ...bookWithInstances.Instances.map((bi) => bi.Label),
            ...bookWithInstances.Book.Authors,
          ]
            .join(" ")
            .toLowerCase()
            .includes(this.state.lazy_search_query.toLowerCase())
      : () => true;
  }

  private fetch = this.create_fetch(this.state.lazy_search_query);

  private book_filter = this.create_book_filter(this.state.lazy_search_query);

  private readonly timeout_ms = 500;
  private timeout: NodeJS.Timeout | null = null;

  private readonly update_query = (new_value: string) => {
    if (this.timeout !== null) {
      clearTimeout(this.timeout);
    }
    this.timeout = setTimeout(() => {
      this.fetch = this.create_fetch(new_value);
      this.setState({ eager_search_query: new_value });
    }, this.timeout_ms);
    this.book_filter = this.create_book_filter(new_value);
    this.setState({ lazy_search_query: new_value });
  };

  render() {
    const page_state = this.props.page_state;
    return (
      <div>
        <div className="my-2 flex items-center gap-1">
          <Input
            mode="rock"
            value={this.state.lazy_search_query}
            className="block w-full"
            type="text"
            size="s"
            placeholder={i18next.t(
              "Search books by title, authors, description"
            )}
            onChange={(event) => this.update_query(event.target.value)}
          />
          {!this.state.adding_book && page_state.user.UserRole === "admin" && (
            <Button
              mode="rock"
              onClick={(_) => this.setState({ adding_book: true })}
            >
              {i18next.t("Add a book")}
            </Button>
          )}
        </div>
        {this.state.adding_book && (
          <AddBook
            pageState={page_state}
            callbacks={this}
            closeForm={() => this.setState({ adding_book: false })}
          />
        )}
        <BookComponentList
          key={
            "library_books_" +
            this.state.book_list_version +
            "_" +
            this.state.eager_search_query
          }
          fetch={this.fetch}
          filter_books={this.book_filter}
          page_state={this.props.page_state}
          order_link={
            this.props.page_state.library.OrderLink ||
            "https://youtrack.jetbrains.com/"
          }
          callbacks={this.props.callbacks}
        />
      </div>
    );
  }

  addBook(details: BookDetails, instances: BookInstanceWithStatus[]): void {
    const page_state = this.props.page_state;
    fetchOrLogin(
      this,
      process.env.REACT_APP_BOOK_API_URL + "/rest/book",
      "POST",
      JSON.stringify({
        item: { ...details, CreatedBy: page_state.user.UserID },
      })
    ).then((res) => {
      const id = LibraryBookSection.createdId(res);
      if (id === null) return;

      Promise.all(instances.map((i) => this.addInstance(id, i))).then(() => {
        this.setState((prevState) => ({
          adding_book: false,
          book_list_version: prevState.book_list_version + 1,
        }));
      });
    });
  }

  private addInstance(
    bookId: number,
    instance: BookInstanceWithStatus
  ): Promise<any> {
    const createRequest = fetchOrLogin(
      this,
      process.env.REACT_APP_BOOK_API_URL + "/rest/book_instance",
      "POST",
      JSON.stringify({ item: { ...instance, BookID: bookId } })
    );
    if (instance.TakenBy !== null) {
      return createRequest.then((res) => {
        const id = LibraryBookSection.createdId(res);
        if (id === null) return;

        this.changeInstanceOwnerRemote({ ...instance, BookInstanceID: id });
      });
    }
    return createRequest;
  }

  private static createdId(response: Response): number | null {
    const location = response.headers.get("location");
    if (!location) return null;

    const slash = location.lastIndexOf("/");
    if (slash < 0) return null;

    return parseInt(location.substring(slash + 1), 10);
  }

  private changeInstanceOwnerRemote(updatedInstance: BookInstanceWithStatus) {
    const page_state = this.props.page_state;
    fetchOrLogin(
      this,
      process.env.REACT_APP_BOOK_API_URL + "/rest/book_tx",
      "POST",
      JSON.stringify({
        item: {
          BookInstanceID: updatedInstance.BookInstanceID,
          TakenBy: updatedInstance.TakenBy,
          TransactionBy: page_state.user.UserID,
        },
      })
    );
  }
}

export default LibraryBookSection;
