import { LoadingIcon } from "@rescui/icons";
import { Component } from "react";

import {
  ErrorAwareState,
  handleLoadingErrors,
  obtainToken,
} from "../../Util/common";

interface State extends ErrorAwareState {
  loading: boolean;
  login: boolean;
}

class Login extends Component<{}, State> {
  state: State = { loading: true, login: false };

  componentDidMount() {
    const token = obtainToken(this.props);
    handleLoadingErrors(
      this,
      fetch(process.env.REACT_APP_API_URL + "/session", {
        method: "GET",
        headers: { Authorization: "Bearer " + token },
        credentials: "include",
      }).then((res) => {
        if (res.ok) {
          this.setState({
            loading: false,
            login: true,
          });
        } else {
          this.setState({
            loading: false,
          });
          return Promise.reject(res);
        }
      })
    );
  }

  render() {
    const location =
      localStorage.getItem("location") ??
      "https://" + process.env.REACT_API_DOMAIN;
    if (this.state.loading) {
      return <LoadingIcon />;
    } else if (this.state.login) {
      window.location.href = location;
      return null;
    } else {
      return <div>{this.state.loadingError}</div>;
    }
  }
}

export default Login;
