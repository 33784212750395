import "./index.css";

import { CheckIcon, LoadingIcon } from "@rescui/icons";
import { Input } from "@rescui/input";
import sortBy from "lodash/sortBy";
import React, { Component } from "react";

import logo from "../../images/jetid-icon-80.svg";

interface State {
  employees: EmployeeEntry[];
  loading: string | null;
  token: string | null;
  location_id: string | null;
  focused: string | null;
  search: string;
  help_message: string;
  date: string | null;
  refreshing: boolean;
  refresh_timeout: number;
}

interface EmployeeEntry {
  full_name: string;
  employee_id: string;
  marked: boolean;
}

function sortEmployees(employees: EmployeeEntry[]) {
  return sortBy(employees, (employee) => {
    const fullName = employee.full_name;
    const parts = fullName.split(" ");
    return parts.length === 2 ? parts[1] + " " + parts[0] : fullName;
  });
}

function EmployeeCard({
  employee,
  loading = false,
  focused = false,
}: {
  employee: EmployeeEntry;
  loading?: boolean;
  focused?: boolean;
}) {
  const employeeName = employee.full_name;
  let className = "employee_entry";

  if (focused) className += " focused";
  if (employee.marked) className += " marked";

  const icon = loading ? (
    <LoadingIcon size="m" />
  ) : employee.marked ? (
    <CheckIcon size="m" />
  ) : null;

  return (
    <div className={className}>
      {employeeName} {icon}
    </div>
  );
}

class FitnessUserList extends Component<any, State> {
  private readonly apiBaseURL: string =
    process.env.REACT_APP_INCOGNITO_API_URL + "/fitness-tablet";

  private readonly defaultHelpMessage: string =
    "Use the search bar to find yourself quickly.";

  private generate_refresh_timeout: () => number = () => {
    return window.setTimeout(this.refresh, 3_600_000);
  };

  private regenerate_refresh_timeout: () => void = () => {
    window.clearTimeout(this.state.refresh_timeout);
    this.setState({ refresh_timeout: this.generate_refresh_timeout() });
  };

  constructor(props: { focused: string | null; location: any }) {
    super(props);

    const focused = "focused" in props ? props.focused : null;

    this.state = {
      employees: [],
      loading: null,
      token: new URLSearchParams(window.location.hash.substr(1)).get("token"),
      location_id: new URLSearchParams(window.location.hash.substr(1)).get(
        "location_id"
      ),
      focused: focused,
      search: "",
      help_message: this.defaultHelpMessage,
      date: null,
      refreshing: false,
      refresh_timeout: this.generate_refresh_timeout(),
    };
  }

  refresh = () => {
    if (this.state.refreshing) return;
    this.setState({ refreshing: true });
    this.loadData().then(() => this.setState({ refreshing: false }));
    this.regenerate_refresh_timeout();
  };

  loadData = () => {
    return fetch(`${this.apiBaseURL}/list`, {
      method: "POST",
      body: JSON.stringify({
        token: this.state.token,
        location_id: this.state.location_id,
      }),
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          this.setState({ help_message: res.statusText });
          return Promise.reject(res);
        }
      })
      .then((data) =>
        this.setState({
          employees: sortEmployees(data["employees"]),
          date: data["today_presentable"],
          help_message: this.defaultHelpMessage,
          focused: null,
          loading: null,
          search: "",
        })
      )
      .catch(console.error);
  };

  componentDidMount() {
    if (!this.state.token) {
      this.setState({
        help_message:
          "Please specify the access token in the URL fragment: #token=...&location_id=...",
      });
    } else if (!this.state.location_id) {
      this.setState({
        help_message:
          "Please specify the location ID in the URL hash: #token=...&location_id=...",
      });
    } else {
      this.loadData();
    }
    window.addEventListener("mouseover", this.regenerate_refresh_timeout, {
      passive: true,
    });
    window.addEventListener("scroll", this.regenerate_refresh_timeout, {
      passive: true,
    });
    window.addEventListener("keydown", this.regenerate_refresh_timeout, {
      passive: true,
    });
  }

  componentWillUnmount() {
    window.clearTimeout(this.state.refresh_timeout);
    window.removeEventListener("mouseover", this.regenerate_refresh_timeout);
    window.removeEventListener("scroll", this.regenerate_refresh_timeout);
    window.removeEventListener("keydown", this.regenerate_refresh_timeout);
  }

  singleClick = (employee: EmployeeEntry) => {
    if (this.state.loading) {
      return;
    }
    const focused = this.state.focused === employee.employee_id;
    const marked = employee["marked"];

    if (!focused) {
      this.focus(employee);
    } else {
      marked ? this.doUnmark(employee) : this.doMark(employee);
    }
  };

  focus = (employee: EmployeeEntry) => {
    const help_message = employee["marked"]
      ? "You're already marked. Tap again to unmark."
      : "Tap again to mark yourself.";
    this.setState({
      focused: employee.employee_id,
      help_message: help_message,
    });
  };

  doMark = (employee: EmployeeEntry) => {
    const token = this.state.token;
    this.setState({
      loading: employee["employee_id"],
    });
    const body: any = {
      token: token,
      location_id: this.state.location_id,
      employee_id: employee["employee_id"],
    };
    body["method"] = "tablet";
    fetch(`${this.apiBaseURL}/mark`, {
      method: "POST",
      body: JSON.stringify(body),
    })
      .then((res) => {
        if (res.ok) {
          employee["marked"] = true;
          this.setState((prevState) => {
            return {
              loading: null,
              search: "",
              employees: sortEmployees(prevState.employees),
              help_message: this.defaultHelpMessage,
            };
          });
        } else {
          this.setState({ help_message: res.statusText });
          return Promise.reject(res);
        }
      })
      .catch(console.error);
  };

  doUnmark = (employee: EmployeeEntry) => {
    const token = this.state.token;
    this.setState({
      loading: employee["employee_id"],
    });
    fetch(`${this.apiBaseURL}/unmark`, {
      method: "POST",
      body: JSON.stringify({
        token: token,
        location_id: this.state.location_id,
        employee_id: employee["employee_id"],
      }),
    })
      .then((res) => {
        if (res.ok) {
          employee["marked"] = false;
          this.setState((prevState) => {
            return {
              loading: null,
              search: "",
              employees: sortEmployees(prevState.employees),
              help_message: this.defaultHelpMessage,
            };
          });
        } else {
          this.setState({ help_message: res.statusText });
          return Promise.reject(res);
        }
      })
      .catch(console.error);
  };

  render() {
    if (this.state.employees.length === 0)
      return (
        <div>
          <LoadingIcon />
          <div>
            <h4 className="rs-h4 rs-h4_theme_dark">
              {this.state.help_message}
            </h4>
          </div>
        </div>
      );
    else {
      const employees = this.state.employees;
      const filteredEmployees = employees.filter((employee) => {
        return employee.full_name
          .toLowerCase()
          .split(" ")
          .some((s) => s.startsWith(this.state.search.toLowerCase()));
      });

      return (
        <div className="tablet_page">
          <div className="content">
            <div className="tablet_header">
              <img src={logo} className="logo" alt="JetID logo" />
              <h2 className="rs-h2 rs-h2_theme_dark">
                Tap on your name and don't skip the warm-up
              </h2>
            </div>
            <div className="tablet_date">
              <h2 className="rs-h2 rs-h2_theme_dark" onClick={this.refresh}>
                {this.state.refreshing ? <LoadingIcon /> : this.state.date}
              </h2>
            </div>
          </div>
          <div className="content space">
            <div className="search rs-subtitle-2 rs-subtitle-2_theme_dark">
              <Input
                value={this.state.search}
                onChange={(value) =>
                  this.setState({ search: value.target.value })
                }
                theme="dark"
                disabled={this.state.refreshing}
                placeholder="Enter your name"
              />
            </div>
          </div>
          <div className="fitness_list" id="employee_list">
            {this.generateEmployeeList(filteredEmployees)}
          </div>

          <div className="tablet_help_message">
            <h4 className="rs-h4 rs-h4_theme_dark">
              {this.state.help_message}
            </h4>
          </div>
        </div>
      );
    }
  }

  componentDidUpdate(
    prevProps: Readonly<any>,
    prevState: Readonly<State>,
    snapshot?: any
  ) {
    if (this.state.focused !== null && prevState.search !== this.state.search) {
      const el = document.getElementById(this.state.focused);
      const list = document.getElementById("employee_list");
      // let's check if the element is visible
      if (list && el) {
        const { offsetTop } = el;
        const { scrollTop, clientHeight } = list;
        const listOffsetTop = list.offsetTop;
        const elOffsetTopInList = offsetTop - listOffsetTop;
        if (
          elOffsetTopInList < scrollTop + 50 ||
          elOffsetTopInList > scrollTop + clientHeight - 50
        ) {
          list.scrollTop = elOffsetTopInList - clientHeight / 2;
        }
      }
    }
  }

  generateEmployeeList = (filteredEmployees: EmployeeEntry[]) => {
    if (this.state.refreshing) return <LoadingIcon size="l" theme="dark" />;
    if (filteredEmployees.length === 0) {
      return (
        <div className="space">
          <div className="rs-subtitle-2 rs-subtitle-2_theme_dark">
            Can't find yourself?
          </div>
          <div className="rs-subtitle-2 rs-subtitle-2_theme_dark">
            If you're an employee:
          </div>
          <h4 className="rs-h4 rs-h4_theme_dark">
            Please tell the reception desk about this issue
          </h4>
        </div>
      );
    }
    return (
      <ul className="rs-subtitle-1 rs-subtitle-1_theme_dark space">
        {filteredEmployees.map((employee) => {
          const employeeId = employee.employee_id;

          return (
            <li
              onClick={() => this.singleClick(employee)}
              id={employeeId}
              key={employeeId}
            >
              <EmployeeCard
                employee={employee}
                loading={this.state.loading === employeeId}
                focused={this.state.focused === employeeId}
              />
            </li>
          );
        })}
      </ul>
    );
  };
}

export default FitnessUserList;
