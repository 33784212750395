export type Props = Record<string, never>;

function Footer() {
  return (
    <footer className="mb-2 flex justify-center">
      <p>
        <small>Website deployed on {process.env.REACT_APP_DEPLOY_DATE}</small>
      </p>
    </footer>
  );
}

export default Footer;
