import { downloadAsFile } from "../Util/common";
import fetch from "./fetch";

type FetchParams = Parameters<typeof fetch>;

type Input = FetchParams[0];
type Options = FetchParams[1];

export default async function download(
  filename: string,
  mediatype: string,
  input: Input,
  options?: Options
) {
  const response = await fetch(input, options);
  await downloadAsFile(response, mediatype, filename);
}
