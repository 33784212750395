import Button from "@rescui/button";
import { DownloadIcon, LoadingIcon } from "@rescui/icons";
import React from "react";

import useAuthenticatedUser from "../../auth/useAuthenticatedUser";
import Footer from "../../layout/Footer";
import Header from "../../layout/Header";
import Layout from "../../layout/Layout";
import Main from "../../layout/Main";
import * as mediatypes from "../../shared/mediatypes";
import useDocumentTitle from "../../shared/useDocumentTitle";
import useDownload from "../../shared/useDownload";

type ReportConfig = {
  locationId: string;
  countryId: string;
  businessEntityId: string;
  excludedLocations: string;
  limitToLocation: string;
  withCatering: string;
};

function useReport(config: ReportConfig) {
  const params = new URLSearchParams({
    location_id: config.locationId,
    country_id: config.countryId,
    business_entity_id: config.businessEntityId,
    excluded_locations: config.excludedLocations,
    limit_to_location: config.limitToLocation,
    with_catering: config.withCatering,
  });

  return useDownload(
    "monthly_report.xlsx",
    mediatypes.xlsx,
    `${process.env.REACT_APP_API_URL}/absence_report?${params}`,
    {
      method: "POST",
      headers: { accept: mediatypes.xlsx },
      credentials: "include",
      timeout: 60000,
    }
  );
}

type FitnessReportConfig = {
  locationId: string;
};

function useFitnessReport(config: FitnessReportConfig) {
  const params = new URLSearchParams({ location_id: config.locationId });

  return useDownload(
    "monthly_report.xlsx",
    mediatypes.xlsx,
    `${process.env.REACT_APP_API_URL}/fitness_report?${params}`,
    {
      method: "POST",
      headers: { accept: mediatypes.xlsx },
      credentials: "include",
      timeout: 60000,
    }
  );
}

export type Props = Record<string, never>;

function CateringSummary() {
  useDocumentTitle("JetID Catering Reports");

  const munichReport = useReport({
    countryId: "4ErmII47Szrc", // Germany
    locationId: "1L51AV11kGh1", // MYO
    businessEntityId: "2ASECl0IKfj8", // JetBrains GmbH
    excludedLocations: "1VSTug1k3zI8", // Berlin
    limitToLocation: "false",
    withCatering: "true",
  });

  const berlinReport = useReport({
    locationId: "1VSTug1k3zI8", // Berlin
    countryId: "4ErmII47Szrc", // Germany
    businessEntityId: "2ASECl0IKfj8", // JetBrains GmbH
    excludedLocations: "",
    limitToLocation: "true",
    withCatering: "false",
  });

  const amsterdamReport = useReport({
    countryId: "3fkpd53c1Sls", // Netherlands
    locationId: "Aqm240Tpb0H", // Amsterdam Terrace Tower
    businessEntityId: "1uTTRX1GDuVV", // JetBrains N.V.
    excludedLocations: "",
    limitToLocation: "false",
    withCatering: "false",
  });

  const munichFitnessReport = useFitnessReport({
    locationId: "1L51AV11kGh1", // MYO
  });

  const user = useAuthenticatedUser();

  return (
    <Layout>
      <Header title="Catering Reports" />
      <Main>
        <div className="flex flex-col items-center gap-1">
          {user.isLoading ? (
            <LoadingIcon />
          ) : user.error ? (
            <div className="error">{user.error.message}</div>
          ) : (
            <>
              <Button
                onClick={amsterdamReport.download}
                size="m"
                icon={
                  amsterdamReport.isLoading ? <LoadingIcon /> : <DownloadIcon />
                }
                disabled={amsterdamReport.isLoading}
              >
                Amsterdam
              </Button>
              <Button
                onClick={berlinReport.download}
                size="m"
                icon={
                  berlinReport.isLoading ? <LoadingIcon /> : <DownloadIcon />
                }
                disabled={berlinReport.isLoading}
              >
                Berlin
              </Button>
              <Button
                onClick={munichReport.download}
                size="m"
                icon={
                  munichReport.isLoading ? <LoadingIcon /> : <DownloadIcon />
                }
                disabled={munichReport.isLoading}
              >
                Munich
              </Button>
              <Button
                onClick={munichFitnessReport.download}
                size="m"
                icon={
                  munichFitnessReport.isLoading ? (
                    <LoadingIcon />
                  ) : (
                    <DownloadIcon />
                  )
                }
                disabled={munichFitnessReport.isLoading}
              >
                Munich Fitness
              </Button>
            </>
          )}
        </div>
      </Main>
      <Footer />
    </Layout>
  );
}

export default CateringSummary;
