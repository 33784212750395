import "./App.css";

import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import BookList from "./Views/BookList";
import CateringSummary from "./Views/CateringSummary";
import FitnessUserList from "./Views/FitnessUserList";
import Landing from "./Views/Landing";
import Login from "./Views/Login";
import MassageLanding from "./Views/Massage/landing";
import Timesheet from "./Views/Timesheet";

function App() {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/login" component={Login} />
        <Route exact path="/" component={Landing} />
        <Route exact path="/fitness-tablet" component={FitnessUserList} />
        <Route exact path="/catering" component={CateringSummary} />
        <Route exact path="/timesheet" component={Timesheet} />
        <Route exact path="/book" component={BookList} />
        <Route exact path="/massage" component={MassageLanding} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
