import { useCallback } from "react";
import useSWRMutation from "swr/mutation";

import download from "../client/download";

type DownloadParams = Parameters<typeof download>;

type Name = DownloadParams[0];
type MediaType = DownloadParams[1];
type Input = DownloadParams[2];
type Options = DownloadParams[3];

export default function useDownload(
  filename: Name,
  mediatype: MediaType,
  input: Input,
  options?: Options
) {
  const action = useSWRMutation([input, options], async ([...params]) => {
    await download(filename, mediatype, ...params);
  });

  const trigger = useCallback(() => action.trigger(), [action]);

  return {
    isLoading: action.isMutating,
    error: action.error,
    reset: action.reset,
    download: trigger,
  };
}
